import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, MenuItem, Select, FormControl, 
  InputLabel, Container, Snackbar, Alert, CircularProgress, Autocomplete, Link 
} from '@mui/material';
import axios from 'axios';

const MenuItemUpdate = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({ 
    active: true, price: '', image: '', name: '', cuisines: [] 
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post('https://backend-pickup.au-ki.com/api/pickup/order/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data.imageUrl; // URL returned from backend
    } catch (error) {
      showAlert('Error uploading image');
      return null;
    }
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend-pickup.au-ki.com/api/pickup/order/restaurants');
        setRestaurants(response.data);
      } catch (error) {
        showAlert('Error fetching restaurants!');
      }
    };
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      const fetchCategories = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `https://backend-pickup.au-ki.com/api/pickup/order/categories?restaurantId=${restaurantId}`
          );

          if (response.data.length === 0) {
            showAlert('No categories found for the selected restaurant.');
          } else {
            setCategories(response.data);
            showAlert('Categories fetched successfully!');
          }
        } catch (error) {
          showAlert('Error fetching categories!');
        } finally {
          setIsLoading(false);
        }
      };
      fetchCategories();
    }
  }, [restaurantId]);

  useEffect(() => {
    if (selectedCategory) {
      const fetchMenuItems = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `https://backend-pickup.au-ki.com/api/pickup/order/items?categoryId=${selectedCategory}`
          );

          if (response.data.length === 0) {
            showAlert('No menu items found for the selected category.');
          } else {
            setMenuItems(response.data);
            showAlert('Menu items fetched successfully!');
          }
        } catch (error) {
          showAlert('Error fetching menu items!');
        } finally {
          setIsLoading(false);
        }
      };
      fetchMenuItems();
    }
  }, [selectedCategory]);

  const handleItemChange = (event, value) => {
    const item = menuItems.find((item) => item.id === value?.id);
    setSelectedItem(item || null);
    setFormData({
      active: item?.active ?? true,
      price: item?.price || '',
      image: item?.image || '',
      name: item?.name || '',
      cuisines: item?.cuisines || [],
    });
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      let imageUrl = formData.image;
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
        if (!imageUrl) throw new Error('Image upload failed');
      }
      await axios.put('https://backend-pickup.au-ki.com/api/pickup/order/update', { 
        ...formData, image: imageUrl, id: selectedItem.id 
      });
      showAlert('Menu item updated successfully!');
      resetForm();
    } catch (error) {
      showAlert('Failed to update menu item.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedItem(null); 
    setFormData({ active: true, price: '', image: '', name: '', cuisines: [] });
    setImageFile(null); // Clear the image file selection
  };

  const handleCloseAlert = () => setOpenAlert(false);

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
        Update Menu Item
      </Typography>

      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Restaurant</InputLabel>
          <Select 
            value={restaurantId} 
            onChange={(e) => setRestaurantId(e.target.value)}
            disabled={isLoading || !restaurants.length}
          >
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select 
            value={selectedCategory} 
            onChange={(e) => setSelectedCategory(e.target.value)}
            disabled={isLoading || !categories.length}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          options={menuItems}
          getOptionLabel={(option) => option.name}
          onChange={handleItemChange}
          value={selectedItem}
          renderInput={(params) => (
            <TextField {...params} label="Search Menu Item" fullWidth />
          )}
          disabled={isLoading || !menuItems.length}
        />

        {selectedItem && (
          <>
            <TextField
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Price"
              value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              fullWidth
            />
            
            {/* Show existing image URL if available */}
            {formData.image && (
              <Link href={formData.image} target="_blank" rel="noopener" sx={{ mb: 1 }}>
                View Current Image
              </Link>
            )}

            <TextField
              label="Choose Image"
              type="file"
              onChange={(e) => setImageFile(e.target.files[0])}
              fullWidth
              InputLabelProps={{
                shrink: true, // Prevent overlap of label with file picker
              }}
            />

            <TextField
              label="Cuisines (comma-separated)"
              value={formData.cuisines.join(', ')}
              onChange={(e) => setFormData({ ...formData, cuisines: e.target.value.split(',').map(c => c.trim()) })}
              fullWidth
            />
            <FormControl>
              <InputLabel>Active</InputLabel>
              <Select
                value={formData.active}
                onChange={(e) => setFormData({ ...formData, active: e.target.value })}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>

            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleUpdate}
              disabled={isLoading || !selectedItem}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              Update Menu Item
            </Button>
          </>
        )}
      </Box>

      <Snackbar 
        open={openAlert} 
        autoHideDuration={3000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MenuItemUpdate;
