import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Box, ThemeProvider, createTheme, 
  AppBar, Toolbar, Tabs, Tab, CssBaseline 
} from '@mui/material';
import OrderDisplay from './OrderDisplay';
import SupportTicketsDisplay from './SupportTicketsDisplay';
import MenuItemUpdate from './MenuItemUpdate';
import RestaurantForm from './RestaurantForm';
import axios from 'axios';
import { io } from 'socket.io-client';


const socket = io('https://backend-pickup.au-ki.com'); // Connect to the server

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5' },
  },
  typography: {
    h4: { fontWeight: 600, marginBottom: '20px' },
    body1: { color: '#4a4a4a' },
  },
});

const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeOrderCount, setActiveOrderCount] = useState(0);
  const [activeTicketCount, setActiveTicketCount] = useState(0);

  const fetchCounts = async () => {
    try {
      const ordersResponse = await axios.get('https://backend-pickup.au-ki.com/api/pickup/order/orderData-allOrder');
      const activeOrders = ordersResponse.data.filter(
        (order) =>
          order.order_status.toLowerCase() !== 'completed' && 
          order.order_status.toLowerCase() !== 'order canceled'
      );

      const ticketsResponse = await axios.get('https://backend-pickup.au-ki.com/api/pickup/order/orderData-allSupport');
      const activeTickets = ticketsResponse.data.openTickets;

      setActiveOrderCount(activeOrders.length);
      setActiveTicketCount(activeTickets.length);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    fetchCounts();

    // Listen for real-time updates from the server
    socket.on('newOrder', () => {
      fetchCounts(); // Refresh counts when a new order is received
    });

    // Cleanup listeners on component unmount
    return () => {
      socket.off('newOrder');
    };
  }, []);

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Top Navigation with Tabs */}
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} align="center">
              SUPPORT DASHBOARD
            </Typography>
          </Toolbar>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label={`Orders [${activeOrderCount}]`} />
            <Tab label={`Tickets [${activeTicketCount}]`} />
            <Tab label="Update Menu Item" />
            <Tab label="Restaurant" />
          </Tabs>
        </AppBar>

        {/* Main Content */}
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>
          <Container maxWidth="xl">
            {activeTab === 0 && <OrderDisplay refreshCounts={fetchCounts} />}
            {activeTab === 1 && <SupportTicketsDisplay refreshCounts={fetchCounts} />}
            {activeTab === 2 && <MenuItemUpdate />}
            {activeTab === 3 && <RestaurantForm />}
            
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
