import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import {
  Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Typography, FormControl, Select, MenuItem,
  Collapse, IconButton, Box, TextField, Grid, Snackbar, Alert
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Close as CloseIcon, Cancel as CancelIcon } from '@mui/icons-material';

const socket = io('https://backend-pickup.au-ki.com');
const BUFFER_TIME = 5;
const REFRESH_INTERVAL = 20000; // 20 seconds

const statusFlow = [
  'Order Received',
  'Confirmed by Restaurant',
  'Preparing',
  'Ready for Pickup',
  'Completed'
];

const orderStatuses = [
  'Order Received',
  'Confirmed by Restaurant',
  'Preparing',
  'Ready for Pickup',
  'Completed',
  'Order Delayed',
  'Order Canceled'
];

// Function to determine the alert message based on elapsed time
const getAlertMessage = (order, elapsedTime) => {
  const preparationTime = order.preparation_time || 0;
  switch (order.order_status.toLowerCase()) {
    case 'order received':
      return elapsedTime > 10 ? 'Order is not confirmed by Restaurant' : '';
    case 'confirmed by restaurant':
      return elapsedTime > 5 ? 'Not started Preparing' : '';
    case 'preparing':
      return elapsedTime > preparationTime + BUFFER_TIME ? 'Not Ready for Pickup' : '';
    case 'ready for pickup':
      return elapsedTime > 10 ? 'Order not completed' : '';
    default:
      return '';
  }
};

const OrderDisplay = ({ refreshCounts }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [activeOrders, setActiveOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [orderIdSearch, setOrderIdSearch] = useState('');
  const [notifications, setNotifications] = useState([]);

  const audioRef = useRef(null);

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-pickup.au-ki.com/api/pickup/order/restaurants');
      setRestaurants(response.data);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://backend-pickup.au-ki.com/api/pickup/order/orderData-allOrder');
      const orders = response.data;

      let filteredOrders = orders;
      if (selectedRestaurant) {
        filteredOrders = filteredOrders.filter(order => order.restaurantid === selectedRestaurant);
      }
      if (orderIdSearch) {
        filteredOrders = filteredOrders.filter(order => order.id.includes(orderIdSearch));
      }

      setActiveOrders(
        filteredOrders.filter(order =>
          order.order_status.toLowerCase() !== 'completed' && order.order_status.toLowerCase() !== 'order canceled'
        )
      );
      setCompletedOrders(
        filteredOrders.filter(order =>
          order.order_status.toLowerCase() === 'completed' || order.order_status.toLowerCase() === 'order canceled'
        )
      );
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchRestaurants();
    fetchOrders();
    const interval = setInterval(fetchOrders, REFRESH_INTERVAL);

    // Socket.IO: Listen for new orders
    socket.on('newOrder', (newOrder) => {
      setActiveOrders((prevOrders) => [newOrder, ...prevOrders]);
      audioRef.current.play(); // Play alarm sound for new orders
      showSnackbarNotification(newOrder); // Show snackbar notification
    });

    return () => {
      clearInterval(interval); // Cleanup interval on component unmount
      socket.off('newOrder'); // Cleanup socket listener
    };
  }, [selectedRestaurant, orderIdSearch]);

  const showSnackbarNotification = (order) => {
    const message = `New Order from ${order.restaurant_name || 'Unknown'} (ID: ${order.id})`;
    setNotifications((prevNotifications) => [...prevNotifications, { id: order.id, message }]);
  };
  

  const handleSnackbarClose = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };
  

  const handleClearSearch = () => {
    setOrderIdSearch('');
  };

  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await axios.put(`https://backend-pickup.au-ki.com/api/pickup/order/order-status?orderId=${orderId}`, {
        order_status: newStatus,
      });
      fetchOrders();
      refreshCounts();
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const getElapsedTime = (order) => {
    const lastStatusChange = getLastStatusChangeTime(order);
    const now = new Date();
    return Math.floor((now - lastStatusChange) / (1000 * 60)); // In minutes
  };

  const getLastStatusChangeTime = (order) => {
    switch (order.order_status.toLowerCase()) {
      case 'order received':
        return new Date(order.created_at);
      case 'confirmed by restaurant':
        return new Date(order.confirmed_by_restaurant_at);
      case 'preparing':
        return new Date(order.preparing_at);
      case 'ready for pickup':
        return new Date(order.ready_for_pickup_at);
      default:
        return new Date(order.created_at);
    }
  };

  const sortOrders = (orders) => {
    return orders.sort((a, b) => {
      const aAlert = getAlertMessage(a, getElapsedTime(a));
      const bAlert = getAlertMessage(b, getElapsedTime(b));

      if (aAlert && !bAlert) return -1;
      if (!aAlert && bAlert) return 1;

      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  const ordersToShow = sortOrders(tabIndex === 0 ? activeOrders : completedOrders);

  return (
    <>
      <audio ref={audioRef} src="alarm.mp3" preload="auto" />

      {/* Snackbar for notifications */}
      {notifications.map((notification, index) => (
        <Snackbar
          key={notification.id}
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ top: `${20 + index * 60}px` }}
        >
          <Alert
            onClose={() => handleSnackbarClose(notification.id)}
            severity="info"
            sx={{ width: '100%' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleSnackbarClose(notification.id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}


      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={selectedRestaurant}
              onChange={(e) => setSelectedRestaurant(e.target.value)}
              renderValue={(selected) => (selected ? restaurants.find(r => r.id === selected)?.name : 'All Restaurants')}
            >
              <MenuItem value="">
                <em>All Restaurants</em>
              </MenuItem>
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Search by Order ID"
            variant="outlined"
            fullWidth
            value={orderIdSearch}
            onChange={(e) => setOrderIdSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearSearch}>
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label={`Active Orders (${activeOrders.length})`} />
        <Tab label={`Completed Orders (${completedOrders.length})`} />
      </Tabs>

      {ordersToShow.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Restaurant</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Support Staff</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Timing (min)</TableCell>
                <TableCell>Alert</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersToShow.map((order) => (
                <OrderRow
                  key={order.id}
                  order={order}
                  onStatusChange={handleStatusChange}
                  elapsedTime={getElapsedTime(order)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No orders available.</Typography>
      )}
    </>
  );
};

const OrderRow = ({ order, onStatusChange, elapsedTime }) => {
  const [open, setOpen] = useState(false);
  const alertMessage = getAlertMessage(order, elapsedTime);

  const isStatusDisabled = (currentStatus, status) => {
    if (currentStatus.toLowerCase() === 'order delayed') {
      return !['Completed', 'Order Canceled'].includes(status);
    }

    const currentIndex = statusFlow.findIndex((s) => s.toLowerCase() === currentStatus.toLowerCase());
    const statusIndex = statusFlow.findIndex((s) => s.toLowerCase() === status.toLowerCase());

    if (currentIndex === -1 || statusIndex === -1) return false;

    return statusIndex < currentIndex;
  };

  const updateOrderAlert = async (orderId, alertMessage) => {
    try {
      await axios.put('https://backend-pickup.au-ki.com/api/pickup/order/alert', {
        orderId,
        alertMessage,
      });
      console.log('Alert message saved.');
    } catch (error) {
      console.error('Error updating alert message:', error);
    }
  };

  useEffect(() => {
    if (alertMessage) {
      updateOrderAlert(order.id, alertMessage);
    }
  }, [alertMessage]);

  return (
    <>
      <TableRow>
        <TableCell>{order.id}</TableCell>
        <TableCell>{order.restaurant_name || 'Unknown'}</TableCell>
        <TableCell>{order.user_name || 'N/A'}</TableCell>
        <TableCell>{order.user_phone || 'N/A'}</TableCell>
        <TableCell>{order.support_staff_name} ({order.support_staff_phone || 'N/A'})</TableCell>
        <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
        <TableCell>
          <FormControl fullWidth size="small">
            <Select
              value={order.order_status}
              onChange={(e) => onStatusChange(order.id, e.target.value)}
              displayEmpty
              renderValue={(selected) =>
                selected ? selected : order.order_status || 'Order Received'
              }
            >
              {orderStatuses.map((status) => (
                <MenuItem key={status} value={status} disabled={isStatusDisabled(order.order_status, status)}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell style={{ color: alertMessage ? '#C81D11' : '#17B169' }}>{elapsedTime}</TableCell>
        <TableCell style={{ backgroundColor: alertMessage ? '#C81D11' : 'transparent' }}>
          {alertMessage}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={10} sx={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              {order.item_details.items.map((itemDetail) => (
                <Box key={itemDetail.itemId} sx={{ marginBottom: '10px' }}>
                  <Typography
                    variant="body2"
                    sx={{ paddingLeft: '15px', marginBottom: '5px' }}
                  >
                    <strong>{itemDetail.itemName}</strong> x {itemDetail.quantity} - {itemDetail.price} Rs
                    {itemDetail.customisation && ` (${itemDetail.customisation})`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderDisplay;
