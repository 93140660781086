import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Tabs, Tab, Grid, Typography, Card, CardContent, Divider, Button, Dialog,
  DialogContent, DialogActions, DialogTitle, Box, Switch
} from '@mui/material';

const SupportTicketsDisplay = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');

  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  const fetchTickets = () => {
    axios.get('https://backend-pickup.au-ki.com/api/pickup/order/orderData-allSupport')
      .then(response => {
        const sortedTickets = response.data.openTickets.concat(response.data.closedTickets).sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setOpenTickets(sortedTickets.filter(ticket => ticket.status === 'open'));
        setClosedTickets(sortedTickets.filter(ticket => ticket.status === 'closed'));
      })
      .catch(error => console.error('Error fetching tickets:', error));
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleToggleStatus = (ticketId, currentStatus) => {
    const newStatus = currentStatus === 'open' ? 'closed' : 'open';
    axios.put(`https://backend-pickup.au-ki.com/api/pickup/order/ticket-status?ticketId=${ticketId}`, { status: newStatus })
      .then(() => fetchTickets())
      .catch(error => console.error('Error updating ticket status:', error));
  };

  const handleOpenModal = (url) => {
    setAttachmentUrl(url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setAttachmentUrl('');
  };

  const ticketsToShow = tabIndex === 0 ? openTickets : closedTickets;
  const noTicketsMessage = tabIndex === 0 ? 'No open tickets available.' : 'No closed tickets available.';

  return (
    <>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Open Tickets" />
        <Tab label="Closed Tickets" />
      </Tabs>

      <Grid container spacing={3} sx={{ marginTop: '20px' }}>
        {ticketsToShow.length > 0 ? (
          ticketsToShow.map(ticket => (
            <Grid item xs={12} key={ticket.id}>
              <TicketCard 
                ticket={ticket} 
                onToggle={handleToggleStatus} 
                onOpenModal={handleOpenModal} 
              />
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: '20px', width: '100%' }}>
            {noTicketsMessage}
          </Typography>
        )}
      </Grid>

      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Attachment</DialogTitle>
        <DialogContent>
          <iframe
            src={attachmentUrl}
            title="Attachment"
            style={{ width: '100%', height: '500px', border: 'none' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TicketCard = ({ ticket, onToggle, onOpenModal }) => (
  <Card className="ticket-card" sx={{ padding: 2, marginBottom: '15px', borderRadius: '8px' }}>
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{ticket.id}</Typography>
        <Switch
          checked={ticket.status === 'open'}
          onChange={() => onToggle(ticket.id, ticket.status)}
          color="primary"
        />
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Typography variant="body1" sx={{ marginBottom: '2px' }}><strong>{ticket.category}</strong></Typography>
      <Typography variant="body2" sx={{ marginBottom: '2px' }}>{ticket.description}</Typography>
      <Typography variant="body2" sx={{ marginBottom: '2px' }}>
        User: {ticket.user_name || 'N/A'} ({ticket.user_phone || 'N/A'})
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '2px' }}>Restaurant: {ticket.restaurant_name || 'Unknown'}</Typography>
      <Typography variant="body2" sx={{ marginBottom: '2px' }}>
        Address: {ticket.restaurant_address || 'Unknown'}
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '2px' }}>
        Created At: {new Date(ticket.created_at).toLocaleString()}
      </Typography>

      {ticket.item_details && (
  <>
    <Divider sx={{ marginY: 1 }} />
    {Object.entries(ticket.item_details.items).map(([itemId, itemDetail]) => (
      <Box key={itemId} sx={{ marginBottom: '10px' }}>
        <Typography 
          variant="body2" 
          sx={{ marginBottom: '5px' }}
        >
          <strong>{itemDetail.itemName}</strong> x {itemDetail.quantity}
          {itemDetail.customisation && ` - ${itemDetail.customisation}`}
        </Typography>
      </Box>
    ))}
  </>
)}

{ticket.attachment_url && (
  <Button 
    onClick={() => onOpenModal(ticket.attachment_url)} 
    variant="contained" 
    color="primary"
    sx={{ marginTop: '10px' }}
  >
    View Attachment
  </Button>
)}

    </CardContent>
  </Card>
);

export default SupportTicketsDisplay;
