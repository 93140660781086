import React, { useState } from 'react';
import {
  Container, Box, TextField, Button, Select, MenuItem, InputLabel, FormControl,
  Snackbar, Alert, Typography, CircularProgress
} from '@mui/material';
import axios from 'axios';

const RestaurantForm = () => {
  const [formData, setFormData] = useState({
    name: '', address: '', logo: '', feature_image: '', cuisines: [], avg_cost_per_person: '',
    rating: '', latitude: '', longitude: '', google_map_url: '', support_staff_phone: '',
    is_open: true, support_staff_name: '', preparation_time: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [featureImageFile, setFeatureImageFile] = useState(null);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleImageUpload = async (file, folderName) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post(`https://backend-pickup.au-ki.com/api/pickup/order/upload-image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { folderName }, // Specify the folder name for S3 upload
      });
      return response.data.imageUrl; // URL returned from backend
    } catch (error) {
      showAlert('Error uploading image');
      return null;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      // Upload logo and feature image if files are selected
      let logoUrl = formData.logo;
      let featureImageUrl = formData.feature_image;

      if (logoFile) {
        logoUrl = await handleImageUpload(logoFile, 'restaurant_images');
        if (!logoUrl) throw new Error('Logo upload failed');
      }

      if (featureImageFile) {
        featureImageUrl = await handleImageUpload(featureImageFile, 'restaurant_images');
        if (!featureImageUrl) throw new Error('Feature image upload failed');
      }

      // Update formData with uploaded image URLs
      const updatedFormData = {
        ...formData,
        logo: logoUrl,
        feature_image: featureImageUrl,
      };

      const response = await axios.post('https://backend-pickup.au-ki.com/api/pickup/order/create-Restaurant', updatedFormData);
      setAlertMessage(response.data.message);
      setOpenAlert(true);
      setFormData({
        name: '', address: '', logo: '', feature_image: '', cuisines: [], avg_cost_per_person: '',
        rating: '', latitude: '', longitude: '', google_map_url: '', support_staff_phone: '',
        is_open: true, support_staff_name: '', preparation_time: ''
      });
      setLogoFile(null); // Reset file input
      setFeatureImageFile(null); // Reset file input
    } catch (error) {
      showAlert('Failed to create restaurant.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mt: 3 }}>
        Create New Restaurant
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label="Name" value={formData.name || ''} onChange={(e) => setFormData({ ...formData, name: e.target.value })} fullWidth />
        <TextField label="Address" value={formData.address || ''} onChange={(e) => setFormData({ ...formData, address: e.target.value })} fullWidth />

        <TextField
          label="Logo"
          type="file"
          onChange={(e) => setLogoFile(e.target.files[0])}
          fullWidth
          InputLabelProps={{
            shrink: true, // Ensure label stays above input
          }}
        />

        <TextField
          label="Feature Image"
          type="file"
          onChange={(e) => setFeatureImageFile(e.target.files[0])}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField label="Cuisines" value={formData.cuisines.join(', ')} onChange={(e) => setFormData({ ...formData, cuisines: e.target.value.split(',').map(c => c.trim()) })} fullWidth />
        <TextField label="Average Cost Per Person" value={formData.avg_cost_per_person || ''} onChange={(e) => setFormData({ ...formData, avg_cost_per_person: e.target.value })} fullWidth />
        <TextField label="Rating" value={formData.rating || ''} onChange={(e) => setFormData({ ...formData, rating: e.target.value })} fullWidth />
        <TextField label="Latitude" value={formData.latitude || ''} onChange={(e) => setFormData({ ...formData, latitude: e.target.value })} fullWidth />
        <TextField label="Longitude" value={formData.longitude || ''} onChange={(e) => setFormData({ ...formData, longitude: e.target.value })} fullWidth />
        <TextField label="Google Map URL" value={formData.google_map_url || ''} onChange={(e) => setFormData({ ...formData, google_map_url: e.target.value })} fullWidth />
        <TextField label="Support Staff Phone" value={formData.support_staff_phone || ''} onChange={(e) => setFormData({ ...formData, support_staff_phone: e.target.value })} fullWidth />

        <FormControl fullWidth>
          <InputLabel>Is Open</InputLabel>
          <Select value={formData.is_open} onChange={(e) => setFormData({ ...formData, is_open: e.target.value })}>
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>

        <TextField label="Support Staff Name" value={formData.support_staff_name || ''} onChange={(e) => setFormData({ ...formData, support_staff_name: e.target.value })} fullWidth />
        <TextField label="Preparation Time (mins)" value={formData.preparation_time || ''} onChange={(e) => setFormData({ ...formData, preparation_time: e.target.value })} fullWidth />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={20} />}
        >
          Create Restaurant
        </Button>
      </Box>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RestaurantForm;
